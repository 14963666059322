import './App.css';
import React, { useRef,useEffect,useState } from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import langText from './langText.json';
import AdsComponent from './AdsComponent';

function App() {

  const [langCode,updateLangCode] = useState("en");

  const canvasRef = useRef(null)
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  //console.log(windowWidth);
  //console.log(windowHeight);

  const part1SideL = (windowHeight > windowWidth)?windowWidth-70:windowHeight-70;

  const headTitle = {
    height: 70,
    textAlign: "center",
    fontSize: 50,
    padding: 0,
    margin: 0,
  }

  const container = {
    // borderWidth: 10,
    // borderColor: "rgb(0, 128, 255)",
    // borderStyle: "solid",
    backgroundColor: "rgb(240, 240, 240)",
    //height: windowHeight,
    margin: 0,
    padding: 0,
  }

  const row1 = {
    //margin: part1SideL * 0.02,
    //borderWidth: 1,
    //borderColor: "green",
    //borderStyle: "solid",
    display: "flex",
    flexWrap: 'wrap',
  }

  const part1 = {
    // borderColor: "red",
    // borderWidth: 1,
    // borderStyle: "solid",
    height: part1SideL,
    width: part1SideL,
  }

  const part2 = {
    //borderColor: "blue",
    //borderWidth: 1,
    //borderStyle: "solid",
    //margin: windowHeight * 0.02,
    //height: windowHeight * 0.9,
    width: windowWidth * 0.3,
    display: "flex",
    flexWrap: 'wrap',
  }

  const part3 = {
    //borderColor: "blue",
    //borderWidth: 1,
    //borderStyle: "solid",
    //margin: windowHeight * 0.02,
    //height: windowHeight * 0.9,
    width: windowWidth - part1SideL - (windowWidth * 0.3) - 20,
    display: "flex",
    flexWrap: 'wrap',
  }

  const part21 = {
    width: "230px"
  }

  const part22 = {
    width: "200px"
  }

  const mainCanva = {
    
  }

  const btnSet1 = {
    //margin: 4,
    marginTop:5,
    marginRight:8
  }

  const inputBox = {
    width: windowHeight * 0.25
  }

  const resultW = {
    wordWrap: "break-word",
  }

  const langBox = {
    position:"fixed",
    top:17,
    right:15
  }

  // const row2 = {
  //   position:"fixed",
  //   bottom:0,
  //   zIndex:1002,
  //   textAlign: "center",
  //   width: "100%",
  //   backgroundColor:"gray",
    
  // }
  
  // const row2T1 = {

  // }

  // const [canvasContext, setCanvasContext] = useState(null);

  //let rotatedT = 0;
  let started = 0;
  let runTime = 0;
  let runTimeL = 72;
  let fps = 17;
  let turnRate = 4;
  let tTurn = 0;
  const[tTurnA, settTurnA] = useState(0);

  const [winner,setWinner] = useState("NaN");

  const [players,setPlayers] = useState(["1","2","3"]);

  const drawTurntable = (roAngle,players2 = "") => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d");
    //setCanvasContext(ctx);

    ctx.clearRect(0,0, part1SideL,part1SideL);

    const cX = part1SideL * 0.5;
    const cY = part1SideL * 0.5;

    const r = part1SideL * 0.9 * 0.5; //radius

    let playersT = [];

    if(players2 !== ""){
      playersT = players2;
    }else{
      playersT = players;
    }

    //console.log(players);
    const w = playersT.length; //part of circle
    const m = (2 * Math.PI) / w; //each part of circle arc
    //alert(m);

    let p = 2 / w; 

    if(roAngle !== 0){
      //rotatedT = rotatedT + 1;
      ctx.clearRect(0,0,canvas.width,canvas.height);
      ctx.translate(cX,cY);
      ctx.rotate(roAngle * (Math.PI/180));
      ctx.translate(-cX,-cY);
      //console.log(getRotation(ctx));
      //console.log(roAngle);
    }

    //color
    let alp = 1;
    let change = 1 / (w / 6);

    let colorSet = [
      "rgba(255, 0, 0,"+alp+")",
      "rgba(0, 255, 0,"+alp+")",
      "rgba(0, 0, 255,"+alp+")",
      "rgba(255, 255, 0,"+alp+")",
      "rgba(255, 0, 255,"+alp+")",
      "rgba(0, 255, 255,"+alp+")",
    ];

    let redC = 255;
    let greenC = 255;
    let blueC = 255;

    const colorSetD = [
      [1,0,0],
      [0,1,0],
      [0,0,1],
      [1,1,0],
      [1,0,1],
      [0,1,1]
    ];

    let baseNum = colorSetD.length;

    colorSet = [];

    for(var j = 0;j < w;j++){
        colorSet.push("rgba("+(colorSetD[j%baseNum][0]*redC)+","+(colorSetD[j%baseNum][1]*greenC)+","+(colorSetD[j%baseNum][2]*blueC)+","+alp+")");
        if(colorSetD[j%baseNum][0] < 1){
          if(colorSetD[j%baseNum][0] <= colorSetD[j%baseNum][1] && colorSetD[j%baseNum][0] <= colorSetD[j%baseNum][2]){
            colorSetD[j%baseNum][0] = colorSetD[j%baseNum][0] + change;
            continue;
          }
        }
        if(colorSetD[j%baseNum][2] < 1){
          if(colorSetD[j%baseNum][2] <= colorSetD[j%baseNum][0] && colorSetD[j%baseNum][2] <= colorSetD[j%baseNum][1]){
            colorSetD[j%baseNum][2] = colorSetD[j%baseNum][2] + change;
            continue;
          }
        }
        if(colorSetD[j%baseNum][1] < 1){
          if(colorSetD[j%baseNum][1] <= colorSetD[j%baseNum][0] && colorSetD[j%baseNum][1] <= colorSetD[j%baseNum][2]){
            colorSetD[j%baseNum][1] = colorSetD[j%baseNum][1] + change;
            continue;
          }
        }
    }

    //console.log(colorSet);

    //try draw by part circle
    for(var newi = 0;newi < w;newi++){
      var i = newi;
      ctx.beginPath();
      //const x = cX + r * Math.sin(m * i);
      //const y = cY + r * Math.cos(m * i);
      const x = cX + r * Math.cos(m * i);
      const y = cY + r * Math.sin(m * i);
      ctx.moveTo(cX,cY);
      ctx.lineTo(x,y);
      //ctx.arc(cX,cY,r,(0.5 - (p * i)) * Math.PI, ((0.5+p) - (p * i)) * Math.PI);
      ctx.arc(cX,cY,r,(p * i) * Math.PI, (p * (i+1)) * Math.PI);
      ctx.fillStyle = colorSet[i];
      ctx.fill();
      ctx.lineWidth = 0.5;
      ctx.strokeStyle = colorSet[i];
      ctx.stroke();

      //draw text
      let t1 = "H";
      //t1 = "XXXXXXXX"+i;
      t1 = playersT[i];
      //let fontSize = ((r * 2 * Math.PI) / (w * 3) / t1.length);
      let fontSize = (r * Math.PI) / w / (t1.length+1);
      //console.log(fontSize);
      if(w < 3){
        //console.log("fixed");
        fontSize = (r * Math.PI) / (w+2) / (t1.length+1);
      }
      if(w > 5){
        //console.log("fixed");
        fontSize = (r * Math.PI) / (w-(w-5)) / (t1.length+1);
      }
      if(w > 15){
        //console.log("fixed2");
        fontSize = (r * Math.PI) / (w-(w-7)) / (t1.length+1);
      }
      //console.log(r);
      const tX = (r/1.7);
      const tY = 0;
      //const angleT = -105;
      //const angleT = ((360/w)*i) - 90 - (360/w/2);
      //v2.0
      const angleT = ((360/w)*i) + (360/w/2);
      // console.log(angleT);
      ctx.save();
      ctx.beginPath();
      ctx.fillStyle = "black";
      ctx.font = fontSize+"px Arial";
      //ctx.font = fontSize+"px Arial";
      ctx.textAlign="center";
      ctx.textBaseline="middle";
      ctx.translate(cX,cY);
      //ctx.rotate(angleT * -(Math.PI/180));
      //v2.0
      ctx.rotate(angleT * (Math.PI/180));
      ctx.fillText(t1,tX,tY);
      ctx.restore();
    }


    //draw arrow 
    ctx.save();
    ctx.translate(cX,cY);
    //ctx.rotate(-roAngle * rotatedT * (Math.PI/180));
    ctx.setTransform(1,0,0,1,cX,cY);
    ctx.translate(-cX,-cY);
    ctx.beginPath();
    ctx.moveTo(cX+(part1SideL * 0.83 * 0.5),cY);
    ctx.lineTo(cX+(part1SideL * 0.95 * 0.5),cY-12);
    ctx.lineTo(cX+(part1SideL * 0.95 * 0.5),cY+12);
    ctx.lineTo(cX+(part1SideL * 0.83 * 0.5),cY);
    ctx.fillStyle = "black";
    ctx.fill();
    ctx.restore();

    if(roAngle !== 0){
      runTime = runTime + fps;
      //console.log(runTime);
      //360*2*0.1 -> 360*3*0.1
      if(runTime <= runTimeL){
        setTimeout(() => {
          tTurn = tTurn + turnRate;
          drawTurntable(roAngle);
        }, fps);
      }else{
        started = 0;
        runTime = 0;
        let tTurnTT = 0;
        tTurnTT = tTurnTT + tTurn + tTurnA;
        settTurnA(tTurnTT);
        let winner = Math.floor((360 - (tTurnTT % 360)) / (360/w));
        if(winner === w){
          winner = winner - 1;
        }
        setWinner(players[winner]);

        //tTurn = 0;
      }
    }

  }

  useEffect(() => {
      document.cookie="__Host-name=.doubleclick.net/; Secure; Path=/; SameSite=Strict; Partitioned;"
      drawTurntable(0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const checkLang = () =>{
        let tempCh = navigator.language;
        //tempCh = "en-cc";
          if(langText[tempCh] !== undefined){
            updateLangCode(tempCh);
            return;
          }
        tempCh = tempCh.split("-")[0];
        if(langText[tempCh] !== undefined){
          updateLangCode(tempCh);
        }
      }
      checkLang();
  }, []);

  let pressStart = () =>{
    //console.log(started);
    if(started === 0){
      started = 1;
      runTimeL = Math.floor((Math.random() * ((720 / turnRate) * fps))+((720 / turnRate) * fps));
      tTurn = tTurn + turnRate;
      drawTurntable(turnRate);
      //console.log(runTimeL);
    }
  }

  const playerCh = (value,index) =>{
    //console.log(value+"||"+index);
    let newPlayers = players.slice(0);
    newPlayers[index] = value;
    setPlayers(newPlayers);
    drawTurntable(0,newPlayers);
  }

  const Items = players.map((item,itemIndex) => {
      return (
          <div
            key={itemIndex}
          >
            <input style={inputBox} onChange={e => playerCh(e.target.value,itemIndex)} defaultValue={item} maxLength="50"/>
          </div>
      );
  });

  const addPlayer = () =>{
    if(started === 0){
      let newPlayers = players.slice(0);
      newPlayers.push((newPlayers.length+1).toString());
      setPlayers(newPlayers);
      drawTurntable(0,newPlayers);
      setWinner("NaN");
    }
  }

  const deletePlayer = () =>{
    if(started === 0){
      let newPlayers = players.slice(0);
      if(newPlayers.length -1 < 2){
        alert("必需大於1");
        return;
      }
      newPlayers.splice((newPlayers.length-1),1);
      setPlayers(newPlayers);
      drawTurntable(0,newPlayers);
      setWinner("NaN");
    }
  }

  const changeLang = (newLang) =>{
    if(langText[newLang] !== undefined){
      updateLangCode(newLang);
    }
  }

  return (
    <div style={container}>
      <div style={headTitle}>
        {langText[langCode]["title"]}
      </div>
      <div style={langBox}>
        <Form.Select onChange={e => changeLang(e.target.value)} aria-label="語言">
          <option>{langText[langCode]["lang"]}</option>
          <option value="zh">中文(繁體)</option>
          <option value="zh-cn">中文(简体)</option>
          <option value="en">English</option>
          <option value="hi">हिन्दी</option>
          <option value="es">Español</option>
          <option value="fr">Français</option>
          <option value="ar">اَلْعَرَبِيَّةُ</option>
          <option value="ru">Русский язык</option>
          <option value="pt">Português</option>
          <option value="ur">اُردُو</option>
          <option value="id">bahasa Indonesia</option>
          <option value="de">Deutsch</option>
          <option value="ja">日本語</option>
        </Form.Select>
      </div>
      <div style={row1}>
        <div style={part1}>
            <canvas style={mainCanva} width={part1SideL} height={part1SideL} ref={canvasRef} onClick={pressStart}></canvas>
        </div>
        <div style={part2}>
          <div style={part21}>
            <h3>{langText[langCode]["params"]}</h3>
              {Items}
              <Button style={btnSet1} onClick={addPlayer} variant="secondary">{langText[langCode]["btn1"]}</Button>
              <Button style={btnSet1} onClick={deletePlayer} variant="secondary">{langText[langCode]["btn2"]}</Button>
              <Button style={btnSet1} onClick={pressStart} variant="primary">{langText[langCode]["btn3"]}!</Button>
          </div>
          <div style={part22}>
            <h3>{langText[langCode]["result"]}</h3>
            <h3 style={resultW}>{winner}</h3>
          </div>
        </div>
        <div style={part3}>
          <AdsComponent dataAdSlot='3647768528' />
        </div>
      </div>
      {/* <div style={row2}>
        <h5 style={row2T1}></h5>
      </div> */}
    </div>
  );
}

export default App;
